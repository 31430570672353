<template>
  <div class="col-xs-12 py-3 mb-3 px-5">
    <countdown v-if="showProgressbar" :status="0.01 * countDown"></countdown>
    <loading-overlay :loading="!!claimtokenStatus" :text="claimtokenStatus" />
    <div>
      <modal
        v-if="popupModal"
        @modalconfirm="onModalConfirm"
        @modalclose="onModalClose"
        confirmtext="CONTINUE"
      >
        <template v-slot:body>
          <p class="fw-bolder">
            Proceed to MetaMask to mint your wPUT tokens.
          </p>
          <p class="fw-bolder" style="font-size: 16px; text-align: center; font-weight: bold;">
            If you mint your wPUT now, you then need to wait for 30 Minutes to start a new WRAP,
            because within 30 Minutes you will always get redirected to this page!<br /><br />
            REMEMBER: You can only mint your wPUT tokens once!!<br /><br />
            When you get here a second time because you did not wait for 30 minutes and
            try to mint again, you will get an error transaction in MetaMask!
          </p>
        </template>
      </modal>
    </div>

    <div class="d-sm-none text-start">
      <expiration-warning class="mt-1" :session="session" />

      <div v-if="!!session">
        <div class="text-start wrapinput-label" style="font-size: 16px; margin-top: 10px; margin-bottom: 20px; color: #e5c300; font-weight: bold; text-align: center !important;">
          -------- You are currently wrapping PUT to wPUT!! Please follow the green instructions below! --------<br />
          (If you get kicked out, call up "Wrap PUTinCoin" again and continue your wrap where you left!)
        </div>
      </div>

      <input
        id="sessionnetwork"
        class="form-control wrapinput"
        type="text"
        :value="selectedNetworkDescription"
        readonly
      />
      <div class="wrapinput-label-container text-start">
        <label for="sessionnetwork" class="form-label wrapinput-label "
          >SMART CONTRACT PLATFORM</label
        >
      </div>

      <input
        id="sessionamount"
        class="form-control wrapinput"
        :class="{ invalid: !aboveMinAmount }"
        type="number"
        v-model="amount"
        :readonly="!!session && !!session.session_id"
        @keypress="onlyForCurrency"
      />
      <div class="wrapinput-label-container text-start">
        <table width="100%">
          <tr>
            <td witdh="90%">
              <div v-if="!session.session_id">
                <label for="sessionamount" class="form-label wrapinput-label "
                  >PUTinCoin AMOUNT you want to WRAP (10,000 PUT minimum)</label
                >
              </div>
              <div v-if="!!session && !!session.session_id && session.confirmations < 6">
                <label for="sessionamount" class="form-label wrapinput-label " style="font-size: 16px;"
                  ><strong>Please transfer !!THIS!! PUTinCoin AMOUNT to the below "PUTinCoin DEPOSIT ADDRESS"!!</strong></label
                >
              </div>
              <div v-if="!!session && !!session.session_id && session.confirmations >= 6">
                <label for="sessionamount" class="form-label wrapinput-label "
                  >PUTinCoin AMOUNT spent for WRAP</label
                >
              </div>
            </td>
            <td align="right">
              <div v-if="!!session && !!session.session_id && session.confirmations < 6">
                <span class="copyaddress">
                  <img
                    alt="copy"
                    height="18"
                    src="../assets/copy.svg"
                    @click.stop="copyAmountToClipboard"
                  />
                </span>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div v-if="session.confirmations >= 6">
        <input
          id="amounttoget"
          class="form-control wrapinput"
          style="font-size: 20px; font-weight: bold;"
          type="text"
          v-model="amounttoget"
          readonly
        />
        <div class="wrapinput-label-container text-start">
          <label for="sessionamount" class="form-label wrapinput-label " style="font-size: 16px;"
            ><strong>THIS is the FEE-DEDUCTED AMOUNT of wPUTinCoin TOKEN you will GET!</strong></label
          >
        </div>
      </div>

      <div class="text-start moveup">
        <img
          class="foxy-down"
          alt="MetaMask"
          height="15"
          src="../assets/metamask-fox.svg"
        />
        <input
          id="sessionaccount"
          class="form-control wrapinput accounttext moverightpadding"
          type="text"
          :value="destinationETHAddress"
          readonly
        />
        <div class="wrapinput-label-container text-start">
          <label for="sessionaccount" class="form-label wrapinput-label "
            >CONNECTED ACCOUNT</label
          >
        </div>
      </div>

      <div v-if="!!session.deposit_address && session.confirmations < 6" class="mb-3 qrcodeimage">
        <vue-q-r-code-component
          v-if="!!URIencodeWrapPPCAddress"
          :size="200"
          :text="URIencodeWrapPPCAddress"
        />
      </div>

      <div v-if="!!session.deposit_address && session.confirmations < 6" class="nothing">
        <input
          id="sessiondepositaddress"
          class="form-control wrapinput accounttext moveleftpadding"
          type="text"
          v-model="session.deposit_address"
          readonly
        />
        <div class="wrapinput-label-container text-start">
          <table width="100%">
            <tr>
              <td witdh="90%">
                <label
                  for="sessiondepositaddress"
                  class="form-label wrapinput-label "
                  >PUTinCoin DEPOSIT ADDRESS
                </label>
              </td>
              <td align="right">
                <span class="copyaddress">
                  <img
                    alt="copy"
                    height="18"
                    src="../assets/copy.svg"
                    @click.stop="copyToClipboard"
                  />
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div v-if="!!session.deposit_address && session.confirmations < 6">
        <div class="text-start wrapinput-label" style="font-size: 18px; margin-top: 10px; color: #32e500;">Please deposit the !!EXACT!! PUTinCoin amount above!! When the transaction has received SIX (6) CONFIRMATIONS you'll be automatically forwarded to the next step!</div>
      </div>

      <div v-if="session.confirmations >= 6">
        <div class="text-start wrapinput-label" style="font-size: 18px; margin-top: 10px; color: #32e500;">Waiting for ADMIN VERIFICATION (could take up to 24 hours)!!
          After verification, you will be able to mint your wPUT token (1% bridge fee being deducted from token amount). If it takes too long, you can safely close this window and revisit this page again by calling "putincoin.to",
          connecting your MetaMask with the account with which you have initiated the wrap and pressing "Wrap PUTinCoin" button, if you are not forwared automatically.
        </div>
      </div>
    </div>

    <!-- sm view or larger: -->

    <div class="d-none d-sm-block text-start px-5">
      <expiration-warning class="mt-1" :session="session" />

      <div v-if="!!session">
        <div class="text-start wrapinput-label" style="font-size: 16px; margin-top: 10px; margin-bottom: 20px; color: #e5c300; font-weight: bold; text-align: center !important;">
          -------- You are currently wrapping PUT to wPUT!! Please follow the green instructions below! --------<br />
          (If you get kicked out, call up "Wrap PUTinCoin" again and continue your wrap where you left!)
        </div>
      </div>

      <input
        id="sessionnetwork"
        class="form-control wrapinput"
        type="text"
        :value="selectedNetworkNumberDescription"
        readonly
      />
      <div class="wrapinput-label-container text-start">
        <label for="sessionnetwork" class="form-label wrapinput-label "
          >SMART CONTRACT PLATFORM</label
        >
      </div>

      <input
        id="sessionamount"
        class="form-control wrapinput"
        :class="{ invalid: !aboveMinAmount }"
        type="number"
        v-model="amount"
        :readonly="!!session && !!session.session_id"
        @keypress="onlyForCurrency"
      />
      <div class="wrapinput-label-container text-start">
        <table width="100%">
          <tr>
            <td witdh="90%">
              <div v-if="!session.session_id">
                <label for="sessionamount" class="form-label wrapinput-label "
                  >PUTinCoin AMOUNT you want to WRAP (10,000 PUT minimum)</label
                >
              </div>
              <div v-if="!!session && !!session.session_id && session.confirmations < 6">
                <label for="sessionamount" class="form-label wrapinput-label " style="font-size: 16px;"
                  ><strong>Please transfer !!THIS!! PUTinCoin AMOUNT to the below "PUTinCoin DEPOSIT ADDRESS"!!</strong></label
                >
              </div>
              <div v-if="!!session && !!session.session_id && session.confirmations >= 6">
                <label for="sessionamount" class="form-label wrapinput-label "
                  >PUTinCoin AMOUNT spent for WRAP</label
                >
              </div>
            </td>
            <td align="right">
              <div v-if="!!session && !!session.session_id && session.confirmations < 6">
                <span class="copyaddress">
                  <img
                    alt="copy"
                    height="18"
                    src="../assets/copy.svg"
                    @click.stop="copyAmountToClipboard"
                  />
                </span>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div v-if="session.confirmations >= 6">
        <input
          id="amounttoget"
          class="form-control wrapinput"
          style="font-size: 20px; font-weight: bold;"
          type="text"
          v-model="amounttoget"
          readonly
        />
        <div class="wrapinput-label-container text-start">
          <label for="sessionamount" class="form-label wrapinput-label " style="font-size: 16px;"
            ><strong>THIS is the FEE-DEDUCTED AMOUNT of wPUTinCoin TOKEN you will GET!</strong></label
          >
        </div>
      </div>

      <div class="text-start moveup">
        <img
          class="foxy-down-lg"
          alt="MetaMask"
          height="15"
          src="../assets/metamask-fox.svg"
        />
        <input
          id="sessionaccount"
          class="form-control wrapinput accounttext-lg moverightpadding"
          type="text"
          :value="destinationETHAddress"
          readonly
        />
        <div class="wrapinput-label-container  text-start">
          <label for="sessionaccount" class="form-label wrapinput-label "
            >CONNECTED ACCOUNT</label
          >
        </div>
      </div>

      <div v-if="!!session.deposit_address && session.confirmations < 6" class="mb-3 qrcodeimage">
        <vue-q-r-code-component
          v-if="!!URIencodeWrapPPCAddress"
          :size="200"
          :text="URIencodeWrapPPCAddress"
        />
      </div>

      <div v-if="!!session.deposit_address && session.confirmations < 6" class="xxx">
        <input
          id="sessiondepositaddress"
          class="form-control wrapinput accounttext-lg moveleftpadding"
          type="text"
          v-model="session.deposit_address"
          readonly
        />
        <div class="wrapinput-label-container text-start">
          <table width="100%">
            <tr>
              <td witdh="90%">
                <label
                  for="sessiondepositaddress"
                  class="form-label wrapinput-label "
                  >PUTinCoin DEPOSIT ADDRESS
                </label>
              </td>
              <td align="right">
                <span class="copyaddress">
                  <img
                    alt="copy"
                    height="18"
                    src="../assets/copy.svg"
                    @click.stop="copyToClipboard"
                  />
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div v-if="!!session.deposit_address && session.confirmations < 6">
        <div class="text-start wrapinput-label" style="font-size: 18px; margin-top: 10px; color: #32e500;">Please deposit the !!EXACT!! PUTinCoin amount above!! When the transaction has received SIX (6) CONFIRMATIONS you'll be automatically forwarded to the next step!</div>
      </div>

      <div v-if="session.confirmations >= 6">
        <div class="text-start wrapinput-label" style="font-size: 18px; margin-top: 10px; color: #32e500;">Waiting for ADMIN VERIFICATION (could take up to 24 hours)!!
          After verification, you will be able to mint your wPUT token (1% bridge fee being deducted from token amount). If it takes too long, you can safely close this window and revisit this page again by calling "putincoin.to",
          connecting your MetaMask with the account with which you have initiated the wrap and pressing "Wrap PUTinCoin" button, if you are not forwared automatically.
        </div>
      </div>
    </div>

    <!-- all views -->

    <div class="row" v-if="!session.session_id">
      <div class="col-xs-12 mt-3">
        <button
          v-show="validForm"
          type="button"
          class="btn btn-outline-success"
          @click="wrap"
          :disabled="!validForm"
        >
          WRAP PUTinCoin
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
import axios from "axios";
import { setIntervalAsync } from "set-interval-async/fixed";
import { clearIntervalAsync } from "set-interval-async";
import VueQRCodeComponent from "vue-qrcode-component";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import Countdown from "@/components/Countdown.vue";
import { getNetworks, getContractAddress } from "@/Endpoints.js";
import Modal from "@/components/Modal.vue";
import ABI from "@/abi/erc20.json";
import BaseWrapper from "@/components/BaseWrapper.vue";
import ExpirationWarning from "@/components/ExpirationWarning.vue";
import kprogress from "@/components/kprogress.vue";

export default {
  extends: BaseWrapper,

  emits: ["wrap-step-current"],

  props: {
    propsessionid: String,
    propnetwork: String,
  },

  data() {
    return {
      countDown: 100,
      countDownHandle: null,
      claimtokenStatus: "",
      stepStatus: 1,
    };
  },

  async mounted() {
    this.claimtokenStatus = "";
    this.requestId = this.newId();
    this.networks = getNetworks();

    if (!!this.$store.state.network) {
      this.network = this.$store.state.network;
    }

    this.resetSession();

    this.accounts = this.selectedAccount;

    if (Array.isArray(this.accounts) && this.accounts.length > 0) {
      this.destinationETHAddress = this.accounts[0];
    }

    let sessioncheck = 'https://api.putincoin.to/wrap/address/' + this.destinationETHAddress;

    const existingsession = await axios.get(sessioncheck);

    if (!!existingsession && !!existingsession.data[0]) {
        this.session = existingsession.data;
        this.session.session_id = existingsession.data[0].session_id;

        await this.getSession(this.session.session_id);

        if (!this.session.destination_network) {
          this.network_id = this.propnetwork;
        } else {
          this.network_id = this.session.destination_network;
        }
        this.$store.commit("setNetwork", this.network_id);
        this.amount = this.session.wrap_amount / 100000000;
        this.amounttoget = this.session.wrap_amount_minus_fee / 100000000;
    }

    if (!!this.propsessionid) {
      this.session.session_id = this.propsessionid;
      await this.getSession(this.session.session_id);
      this.network = this.session.destination_network;

      this.$store.commit("setNetwork", this.network);
      this.amount = this.session.wrap_amount;
      this.amounttoget = this.session.wrap_amount_minus_fee;
    }

    // todo replace this with connect() + disconnect():

    if (!!this.countDownHandle) {
      await clearIntervalAsync(this.countDownHandle);
      this.countDownHandle = setIntervalAsync(this.onCountDown, 350);
    } else {
      this.countDownHandle = setIntervalAsync(this.onCountDown, 350);
    }
    //test
    //this.popupModal = true;
  },

  async unmounted() {
    if (!!this.countDownHandle) {
      await clearIntervalAsync(this.countDownHandle);
    }
  },

  computed: {
    header() {
      return "Wrap PUTinCoin";
    },

    selectedNetworkDescription() {
      const nw = this.$store.state.network;
      if (!!nw && this.networks.length > 0) {
        const ne = this.networks.find((n) => n.key === nw);
        if (!!ne) {
          return ne.description;
        }
      }
      return "";
    },

    selectedNetworkNumberDescription() {
      let nwid = parseFloat(this.network_id);
      const nw = this.$store.state.network;
      if (!!nwid) {
        if (nwid = 56) {
        const nw = "BSC_MAINNET";
          if (!!nw && this.networks.length > 0) {
            const ne = this.networks.find((n) => n.key === nw);
            if (!!ne) {
              return ne.description;
            }
          }
        }
      } else {
        if (!!nw && this.networks.length > 0) {
          const ne = this.networks.find((n) => n.key === nw);
          if (!!ne) {
            return ne.description;
          }
        }
      }
      return "";
    },

    //returns a array with selected account
    selectedAccount() {
      if (!!this.$store.state.account) {
        return [this.$store.state.account];
      }
      return [];
    },

    confirmationMax() {
      try {
        if (!this.session || !this.session.confirmations) return 6;
        let max = this.session.confirmations.required;

        if (typeof max === "string" || max instanceof String)
          return parseInt(max);
        else return max;
      } catch (err) {
        return 6;
      }
    },

    confirmationCurrent() {
      try {
        if (!this.session || !this.session.confirmations) return 0;
        let cur = this.session.confirmations.current;

        if (typeof cur === "string" || cur instanceof String)
          return Math.min(100.0, parseInt(cur));
        else return Math.min(100.0, cur);
      } catch (err) {
        return 0;
      }
    },

    percentConfirmations() {
      const cur = Math.ceil(
        100.0 * this.confirmationCurrent * (1.0 / this.confirmationMax)
      );
      return Math.min(100.0, cur);
    },

    /* percentVerified() {
      const cur = Math.ceil(100.0 * this.witnessesVerified * (1.0 / 3.0));
      return Math.min(100.0, cur);
    }, */

    showProgressbar() {
      if (!this.session || !this.session.session_id) return false;

      const ready2Proceed =
        !!this.session &&
        !!this.session.session_id &&
        !!this.session.deposit_address &&
        !!this.session.witness_signature &&
        this.session.deposited_amount >= this.session.wrap_amount;

      return !ready2Proceed && !this.comfirmedProceedMetaMask;
    },

    URIencodeWrapPPCAddress() {
      const scheme = "putincoin";
      const address = this.session.deposit_address;
      const amount = this.session.wrap_amount / 100000000;

      if (!!amount && !!address) {
        return (
          scheme +
          ":" +
          address +
          "?amount=" +
          amount
        );
      }

      return "";
    },

    URIencodeWrapPPCAddressShort() {
      const scheme = "putincoin";
      const address = this.session.deposit_address;
      const amount = this.session.wrap_amount / 100000000;

      if (!!amount && !!address) {
        return scheme + ":" + address + "?amount=" + amount;
      }

      return "";
    },

    URIencodeWrapPPCAddressLink() {
      if (!!this.URIencodeWrapPPCAddress) {
        return (
          "<a href='" +
          this.URIencodeWrapPPCAddress +
          '\' target="_blank" rel="nofollow" >' +
          this.URIencodeWrapPPCAddressShort +
          "</a>"
        );
      }
      return "";
    },

    aboveMinAmount() {
      return this.validAmount && !this.minAmountNotExceeded;
    },

    validForm() {
      return (
        this.validAmount &&
        !!this.network &&
        !!this.destinationETHAddress &&
        !this.minAmountNotExceeded
      );
    },

    witnessesVerified() {
      let verified = 0;
      if (!!this.session && !!this.session.deposit_address) {
        if (!!this.session.witness_signature) verified++;
      }
      return verified;
    },
  },

  methods: {
    async onCountDown() {
      if (this.showProgressbar) {
        this.countDown = this.countDown - 1;
        if (this.countDown < 0.001) {
          await this.getSession(this.session.session_id);
          this.countDown = 100;
        }
      }
    },

    async refreshSession() {
      const session_id = this.session.session_id;

      try {
        let url = 'https://api.putincoin.to/wrap/' + session_id;

        const res = await axios.get(url);

        if (!!res && !!res.data) {
          this.session = res.data;

          this.stepStatus = this.getWrapStatus();
          this.$emit("wrap-step-current", this.stepStatus);
          window.location.reload();
        }
      } catch (err) {
        if (
          !!err.response &&
          !!err.response.data
        )
          console.warn(err.response.data.message);

        this.eventBus.emit("add-toastr", {
          text: `Unable to retrieve session ${session_id}!`,
          type: "error",
        });
      }
    },

    async getSession(session_id) {
      if (!session_id) return;

      try {
        let url = 'https://api.putincoin.to/wrap/' + session_id;

        const res = await axios.get(url);

        if (!!res && !!res.data) {
          this.session = res.data;

          //temporary hack fix:
          if (
            !!this.session &&
            !!this.session.confirmations &&
            this.session.confirmations.required === 0
          ) {
            this.session.confirmations.required = 6;
          }

          this.stepStatus = this.getWrapStatus();
          this.$emit("wrap-step-current", this.stepStatus);
          if (
            !!this.session.witness_signature
          ) {
            this.popupModal = true;
          }
        }
      } catch (err) {
        if (
          !!err.response &&
          !!err.response.data
        )
          console.warn(err.response.data.message);

        this.eventBus.emit("add-toastr", {
          text: `Unable to retrieve session ${session_id}!`,
          type: "error",
        });
      }
    },

    getWrapStatus() {
      let status = 1;
      if (!!this.session && !!this.session.session_id) {
        if (!!this.session.deposit_address) {
          status = 2;
        }

        if (
          status === 2 &&
          !!this.session.confirmations &&
          this.session.confirmations >= 6
        ) {
          status = 3;
        }

        if (
          status < 4 &&
          !!this.session.witness_signature
        ) {
          status = 4;
        }

        if (!!this.claimed) status = 5;
      }
      return status;
    },

    async onModalConfirm() {
      if (
        !this.comfirmedProceedMetaMask &&
        !!this.session.deposit_address &&
        !!this.session.witness_signature
      ) {
        this.popupModal = false;
        this.comfirmedProceedMetaMask = true;
        this.claimed = true;

        await this.claimTokens();
      }
    },

    async wrap() {
      const url = 'https://api.putincoin.to/wrap';
      const parsedamount = this.decimalConvert(this.amount);

      if (
        this.network = "BSC_MAINNET"
      ) {
        this.network_id = 56;
      }
      const data = {
        wrap_amount: parsedamount,
        destination_address: this.destinationETHAddress,
        destination_network: this.network_id,
      };

      const config = {
        params: data,
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          network: this.network,
          "Idempotency-Key": this.requestId,
          "Accept": "application/json",
        },
      };

      try {

        let response = await axios.post(url, '', config);
        this.session = response.data;

        const success = response && !response.error;

        this.stepStatus = this.getWrapStatus();
        this.$emit("wrap-step-current", this.stepStatus);

        await this.getSession(response.data.session_id);
        this.amounttoget = this.session.wrap_amount_minus_fee / 100000000;

        this.eventBus.emit("add-toastr", {
          text: "Wrap Session successfully created!! Please transfer PUT to the generated address!",
          type: success ? "success" : "error",
        });
      } catch (err) {
        if (
          !!err.response &&
          !!err.response.data
        )
          console.warn(err.response.data.message);

        this.eventBus.emit("add-toastr", {
          text: "Unable to wrap!",
          type: "error",
        });
      }
    },

    async claimTokens() {
      this.accounts = await this.getAccounts();
      if (
        !this.accounts ||
        this.accounts.length < 1 ||
        !this.session ||
        !this.session.witness_signature ||
        !this.session.destination_network
      ) {
        return;
      }

      // const contractAddress = getContractAddress(this.session.destination_network);
      const contractAddress = "0xe63913443cc00f7AbbC4506AeB74b3Bcc3fcbbA9";

      if (!contractAddress) {
        return console.error(
          "No contract address found for network: " + this.session.destination_network
        );
      }

      this.claimtokenStatus = "Waiting for mint...";
      try {
        if (!this.web3) this.web3 = new Web3(ethereum);

        const contract = new this.web3.eth.Contract(ABI, contractAddress, {
          from: this.destinationETHAddress,
        });

        // const decimals = await contract.methods.decimals().call();

        const _ = await contract.methods
          .claimTokens(
            this.session.destination_network,
            this.session.destination_address,
            this.session.wrap_amount_minus_fee,
            this.session.deposit_address,
            this.session.witness_signature
          )
          .send();

        this.resetSession();
        this.claimtokenStatus = "";
        this.gotoHome("Successfully wrapped " + this.amount + " PUT to wPUT!");
      } catch (e) {
        console.log(e);
        this.claimtokenStatus = "";
      }
    },

    decimalConvert(amount) {
      if (!amount) return;

      const amounttoparsedot = amount + ".";

      if (amounttoparsedot.toString().split(".", 2)[1].length === 0) {
        let replaced = amounttoparsedot.replace(".", "");
        let convert = replaced.replace(".", "") + "00000000";
        let nb = parseFloat(convert);
        return nb;
      } else if (amounttoparsedot.toString().split(".", 2)[1].length === 1) {
        let replaced = amounttoparsedot.replace(".", "");
        let convert = replaced.replace(".", "") + "0000000";
        let nb = parseFloat(convert);
        return nb;
      } else if (amounttoparsedot.toString().split(".", 2)[1].length === 2) {
        let replaced = amounttoparsedot.replace(".", "");
        let convert = replaced.replace(".", "") + "000000";
        let nb = parseFloat(convert);
        return nb;
      } else if (amounttoparsedot.toString().split(".", 2)[1].length === 3) {
        let replaced = amounttoparsedot.replace(".", "");
        let convert = replaced.replace(".", "") + "00000";
        let nb = parseFloat(convert);
        return nb;
      } else if (amounttoparsedot.toString().split(".", 2)[1].length === 4) {
        let replaced = amounttoparsedot.replace(".", "");
        let convert = replaced.replace(".", "") + "0000";
        let nb = parseFloat(convert);
        return nb;
      } else if (amounttoparsedot.toString().split(".", 2)[1].length === 5) {
        let replaced = amounttoparsedot.replace(".", "");
        let convert = replaced.replace(".", "") + "000";
        let nb = parseFloat(convert);
        return nb;
      } else if (amounttoparsedot.toString().split(".", 2)[1].length === 6) {
        let replaced = amounttoparsedot.replace(".", "");
        let convert = replaced.replace(".", "") + "00";
        let nb = parseFloat(convert);
        return nb;
      } else if (amounttoparsedot.toString().split(".", 2)[1].length === 7) {
        let replaced = amounttoparsedot.replace(".", "");
        let convert = replaced.replace(".", "") + "0";
        let nb = parseFloat(convert);
        return nb;
      } else if (amounttoparsedot.toString().split(".", 2)[1].length === 8) {
        let replaced = amounttoparsedot.replace(".", "");
        let convert = replaced.replace(".", "");
        let nb = parseFloat(convert);
        return nb;
      } else {
        return;
      }
    },

    copyAmountToClipboard() {
      this.amount = this.session.wrap_amount / 100000000;
      navigator.clipboard.writeText(this.amount);
      this.eventBus.emit("add-toastr", {
        text: this.amount + " copied to clipboard!",
        type: "success",
      });
    },

    copyToClipboard() {
      navigator.clipboard.writeText(this.session.deposit_address);
      this.eventBus.emit("add-toastr", {
        text: this.session.deposit_address + " copied to clipboard!",
        type: "success",
      });
    },
  },

  components: {
    VueQRCodeComponent,
    Countdown,
    Modal,
    ExpirationWarning,
    LoadingOverlay,
    kprogress,
  },
};
</script>

<style lang="scss" scoped>
.custprogress {
  background-color: #c7c7c7;
  height: 10px;
}
.uri-wrap-ppc-address {
  font-size: 75%;
}
.copyaddress {
  font-size: 75%;
  color: #221b17;
  border-color: #221b17;
  &:hover {
    color: #8f3e10;
    background-color: transparent;
    border-color: #8f3e10;
  }
}
.accounttext {
  font-size: 63%;
}
.accounttext-lg {
  font-size: 100%;
}
.moverightpadding {
  padding-left: 20px;
}
.moveleftpadding {
  padding-right: 20px;
}
.foxy-down {
  position: relative;
  top: 23px;
}
.foxy-down-lg {
  position: relative;

  top: 28px;
}
.moveup {
  position: relative;
  top: -19px;
}
.qrcodeimage {
  padding: 1%;
}
.qrcodeimage > * {
  width: fit-content !important;
  border-style: solid !important;
  border-width: 3px !important;
  border-color: #fff !important;
}
@media only screen and (max-width: 768px) {
  #sessionaccount { font-size: 63% !important; }
  #sessiondepositaddress { font-size: 63% !important; }
}
.copyaddress {
  position: relative;
  top: -32px;

  &:hover {
    cursor: pointer;
    top: -34px;
  }
}
</style>
