<template>
  <div class="d-sm-none">
    <div class="row g-0">
      <div class="col-12">
        <hr class="connect" />
        <ul id="progressbar">
          <li
            v-for="index in 6"
            :key="index"
            :class="{ active: step >= index }"
          ></li>
        </ul>

        <div class="outer">
          <div class="inner">
            <span class="explain">{{ currentStepText(step) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-none d-sm-block">
    <div class="row g-0">
      <div class="col-12">
        <hr class="connect" />
        <ul id="progressbar">
          <li
            v-for="index in 5"
            :key="index"
            :class="{ active: step >= index }"
          >
            {{ currentStepText(index) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iswrapping: Boolean,
    step: Number,
  },

  methods: {
    currentStepText(stepid) {
      switch (stepid) {
        case 5:
          return "Wrap completed!";
        case 4:
          return "Mint wPUT";
        case 3:
          return "Wait for admin verification";
        case 2:
          return "Send amount / Wait for 6 confirmations";
        default:
          return "Enter amount";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  display: table;
  margin: 0 0 0 0;
  border: 0px solid #e5c300;
}

.outer {
  border: 0px solid red;
  width: 90%;
  position: relative;
  top: -38px;
  left: 20px;
}
.explain {
  font-weight: 600;
  font-size: 9px;
  color: #fff;
  text-transform: uppercase;
}
hr.connect {
  border: 1px solid #e5c300;
  border-radius: 5px;
  opacity: 1;
  width: 66%;
  position: relative;
  left: 17%;
  top: 39px;
}
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
  position: relative;
  left: 6%;
}
#progressbar li {
  list-style-type: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 9px;
  width: 16.66666%;
  float: left;
  position: relative;
  font-weight: 600;
}
#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 44px;
  line-height: 44px;
  display: block;
  font-size: 22px;
  color: #fff;
  background: #e5c300;
  border-radius: 9px;
  margin: 0 auto 5px auto;
}

/*progressbar connectors*/
// #progressbar li:after {
//   content: "";
//   width: 70%;
//   height: 2px;
//   background: #e5c300;
//   position: absolute;
//   left: -38%;
//   top: 23px;
//   z-index: -1; /*put it behind the numbers*/
// }
// #progressbar li:first-child:after {
//   /*connector not needed before the first step*/
//   content: none;
// }

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #000;
  color: white;
}
</style>
